import React, { useEffect, useState } from "react";
import deliveryInfoPrice from "../../utils/deliveryInfoPrice";
import { loadDeliveryInfoPrice } from "../../services/orderService";
import moment from "moment-jalaali";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {Card,Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useCookies } from "react-cookie";
import CircularProgress from '@mui/material/CircularProgress';

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}
const Finance = () => {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key

  const token = cookies["jwt"];
  const date = new Date();
  const [orderList, setOrderList] = useState();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(date);
  const _date = moment(value).format("jYYYYjMMjDD").replace(/\//g, "");
  const theme = createTheme({
    direction: "rtl", // Both here and <body dir="rtl">
  });

  const getOrders = async () => {
    setLoading(true);

    const response = await loadDeliveryInfoPrice({
      wstoken: token,
      startDate: _date,
    });
    try {
      setLoading(true);

      if (response.data.success == "1") {
        setOrderList(response.data.data[0]);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrders();
  }, [value]);

  return (
    <>
      <RTL>
        <ThemeProvider theme={theme}>
          <div dir='rtl'>
            <LocalizationProvider dir='ltr' dateAdapter={AdapterDateFnsJalali}>
              <Typography
                sx={{ margin: "1.5rem 1rem 0.5rem 0" }}
                variant='h6'
                component='h2'
              >
                فیلتر تاریخ:
              </Typography>
              <MobileDatePicker
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                }}
                value={value}
                onChange={(newValue) => setValue(newValue)}
              />
            </LocalizationProvider>
          </div>
        </ThemeProvider>
      </RTL>
      {orderList && !loading ? (
        <>
          <Card sx={{ padding: "1.5rem", marginTop: "1rem" }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <p>جمع قابل دریافت: {deliveryInfoPrice.getTotalPay(orderList)}</p>
              <p>حقوق پایه: {orderList.staticPrice}</p>
            </Stack>
          </Card>
          <Card sx={{ padding: "1.5rem", marginTop: "1rem" }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <p>
                تعداد کل روز: {deliveryInfoPrice.getTotalCountDay(orderList)}
              </p>
              <p>
                مبلغ کل روز: {deliveryInfoPrice.getTotalPriceDay(orderList)}
              </p>
            </Stack>
          </Card>
          <Card sx={{ padding: "1.5rem", marginTop: "1rem" }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <p>
                تعداد انبارداری در روز:
                {deliveryInfoPrice.getTotalInventoryCountDay(orderList)}
              </p>
              <p>
                مبلغ انبارداری در روز:
                {deliveryInfoPrice.getTotalInventoryPriceDay(orderList)}
              </p>
            </Stack>
          </Card>
          <Card sx={{ padding: "1.5rem", marginTop: "1rem" }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <p>
                تعداد عدم امکان در روز:{" "}
                {deliveryInfoPrice.getTotalImposibleCountDay(orderList)}
              </p>
              <p>
                مبلغ عدم امکان در روز:
                {deliveryInfoPrice.getTotalImposiblePriceDay(orderList)}
              </p>
            </Stack>
          </Card>
          <Card sx={{ padding: "1.5rem", marginTop: "1rem" }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <p>
                تعداد انبارداری در ماه:
                {deliveryInfoPrice.getTotalInventoryCountMonth(orderList)}
              </p>
              <p>
                مبلغ انبارداری در ماه:
                {deliveryInfoPrice.getTotalInventoryPriceMonth(orderList)}
              </p>
            </Stack>
          </Card>
          <Card sx={{ padding: "1.5rem", marginTop: "1rem" }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <p>
                تعداد کل ماه:{deliveryInfoPrice.getTotalCountMonth(orderList)}
              </p>
              <p>
                مبلغ کل ماه:
                {deliveryInfoPrice.getTotalPriceMonth(orderList)}
              </p>
            </Stack>
          </Card>
        </>
      ) : (
        <Stack  
         my={4} 
         sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }} >
          <CircularProgress />
        <Typography
          my={2}
          variant='h5'
          component='h2'
          align="center"
        >
          درحال بارگیری ...
        </Typography>
        </Stack>
      )}
    </>
  );
};
export default Finance;
