import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import {
  loadDefaultMessage,
  loadOrderComments,
  addComment,
} from "../../../services/orderService";
import { toast } from "react-toastify";
import CommetSkeleton from "./skeletons/CommentSkeleton";
import { useCookies } from "react-cookie";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};
const CommentModal = ({ open, setOpen, orderCode }) => {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const token = cookies["jwt"];
  const [comment, setComment] = React.useState("");
  const [defaultComment, setDefaultComment] = React.useState("");
  const [defaultComments, setDefaultComments] = React.useState("");
  const [previosComments, setPreviosComments] = React.useState("");
  const [previosComment, setPreviosComment] = React.useState("");

  const getDefaultComments = async () => {
    const response = await loadDefaultMessage({
      wstoken: token,
    });
    try {
      if (response?.data.success == "1") {
        setDefaultComments(response.data.data);
      }
    } catch {
      toast.error("خطا در بارگزاری کامنت های پیش فرض");
    }
  };
  const getOrderComments = async () => {
    const response = await loadOrderComments({
      wstoken: token,
      orderCode: orderCode,
    });
    try {
      if (response?.data.success == "1") {
        setPreviosComments(response.data.data);
      }
    } catch {
      toast.error("خطا در بارگزاری کامنت های قبلی");
    }
  };
  const handleClose = () => setOpen(false);
  const handleDefualtComments = (event) => {
    setDefaultComment(event.target.value);
    setComment(event.target.value);
  };
  const submitComment = async () => {
    const response = await addComment({
      wstoken: token,
      orderCode: orderCode,
      comment: comment,
    });
    try {
      if (response?.data.success == "1") {
        handleClose();
        toast.success("نظر شما با موفقیت ثبت شد");
      }
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    getDefaultComments();
    getOrderComments();
  }, []);
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography variant='h6' component='h2' mb={2}>
              ارسال نظر
            </Typography>
            <FormControl sx={{ width: 300, marginBottom: "1rem" }}>
              <FormControl>
                <Select
                  value={defaultComment}
                  onChange={handleDefualtComments}
                  autoWidth={false}
                  displayEmpty
                  inputprops={{ "aria-label": "Without label" }}
                >
                  <MenuItem value=''>
                    <em>نظرات پیش فرض</em>
                  </MenuItem>
                  {defaultComments &&
                    defaultComments?.map((defaultComment, index) => {
                      return (
                        <MenuItem className="custom-li" key={index} value={defaultComment.message}>
                          {defaultComment.message}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </FormControl>
            <CacheProvider value={cacheRtl}>
              <TextField
                sx={{
                  minWidth: "100%",
                  marginBottom: "1rem",
                  direction: "rtl",
                }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                id='outlined-textarea'
                label='نظر خود را بنویسید'
                placeholder='Placeholder'
                multiline
              />
            </CacheProvider>
            <Typography>نظرات قبلی</Typography>
            <Box
              sx={{
                border: "1px solid gray",
                borderRadius: "8px",
                height: "10rem",
                overflowY: "auto",
                margin: "1rem 0",
                padding: "0.5rem",
              }}
            >
              {previosComments ? (
                previosComments?.map((previosComment, index) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: "5px",
                        backgroundColor: "#f0f1f2",
                        padding: "0.5rem",
                        margin: "1rem 0",
                      }}
                      key={index}
                    >
                      <Typography>{previosComment.commenterName} : {previosComment.comment}</Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                          alignItems: "flex-start",
                          color: "#525151",
                          fontSize: "12px",
                        }}
                      >
                        {previosComment.commentDateStr}
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <CommetSkeleton />
              )}
            </Box>

            <Stack direction='row-reverse'>
              <Button
                onClick={() => submitComment()}
                variant='contained'
                color='success'
              >
                ثبت نظر
              </Button>
              <Button
                sx={{ margin: "0 5px 0 5px" }}
                onClick={() => handleClose()}
                variant='outlined'
                color='error'
              >
                لغو
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default CommentModal;
