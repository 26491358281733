import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Button, Typography, Box } from "@mui/material";
import BarcodeSkeleton from "./skeletons/BarcodeSkeleton";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export default function Search({ afterSearchComplete }) {
  const [text, setText] = useState("");
  const [openBarcodeScanner, setOpenBarcodeScanner] = useState(false);
  const [stopStream, setStopStream] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    borderRadius: "8px",
    p: 3,
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      afterSearchComplete(text);
      setText("");
    }
  };
  const handleSearchWithBarcode = (barcode) => {
    setStopStream(true);
    setTimeout(() => setOpenBarcodeScanner(false), 0);
    afterSearchComplete(barcode);
    //setOpenBarcodeScanner(false);
    setText(barcode);
  };
  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleCameraPermision = () => {
    setOpenBarcodeScanner(true);
  };
  const previewStyle = {
		height: 50,
		width: 200,
	}
  return (
    <>
      <Stack direction="row" alignItems="center" position="relative">
        <div className="search-input-wrapper">
          <div onClick={() => afterSearchComplete(text)} className="search-icon-wrapper">
            <SearchIcon />
          </div>

          <input
            className="search-input"
            onChange={handleChange}
            value={text}
            placeholder="جستجو در سفارشات"
            aria-label="search"
            onKeyDown={handleKeyPress}
          />
        </div>
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => handleCameraPermision()}
          sx={{ backgroundColor: "green", borderRadius: "6px", color: "white" }}
          px={0.7}
          py={0.7}
          m={1}
        >
          <QrCodeScannerIcon />
          <Typography mr={1} sx={{ fontSize: 12 }}>
            بارکد
          </Typography>
        </Stack>
      </Stack>

      <Modal
        open={openBarcodeScanner}
        onClose={() => setOpenBarcodeScanner(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} p={3} border="1px dashed #ccc" borderRadius={8} textAlign="center">
          <Typography variant="subtitle1" m={2}>
            اسکن بارکد
          </Typography>
          <Box mb={3} position="relative" height={"100%"}>
            <BarcodeSkeleton />
            <BarcodeScannerComponent
              width={"100%"}
              height={"100%"}
              stopStream={stopStream}
              style={previewStyle}
              onUpdate={(err, result) => {
                if (result) {
                  handleSearchWithBarcode(result.text);
                }
              }}
            />
          </Box>
          <Stack direction="row-reverse" justifyContent="center" alignItems="center">
            <Button
              sx={{ margin: "0 5px 0 5px" }}
              onClick={() => {
                setOpenBarcodeScanner(false);
                setStopStream(true);
              }}
              variant="outlined"
              color="error"
            >
              لغو
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
