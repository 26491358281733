const STATE_SEND_TO_BRANCH = 16;
const STATE_DELIVERID_TO_BRANCH = 16;
const STATE_SEND_TO_AGENT = 15;
const STATE_SEND_TO_NON_BRANCH = 16;
const STATE_RECEIVE_TO_PROVINCE = 16;
const STATE_DELIVERID_TO_AGENT = 16;
const STATE_SEND_TO_CUSTOMER_AGENT = 18;
const STATE_DELIVERID_TO_AGENT_IMPOSSIBLE = 24;
const STATE_Deliverd = 9;
const STATE_NOT_PAID = 10;
const STATE_BAJEMOATALE_AGENT = 26;
const STATE_BAJEMOATALE_BRANCH = 27;
const STATE_DELIVERY_IMPOSSIBILITY_AGENT = 20;
const STATE_DELIVERID_TO_CUSTOMER = 9;
export default {
  STATE_SEND_TO_BRANCH,
  STATE_DELIVERID_TO_BRANCH,
  STATE_SEND_TO_AGENT,
  STATE_DELIVERID_TO_AGENT,
  STATE_SEND_TO_NON_BRANCH,
  STATE_RECEIVE_TO_PROVINCE,
  STATE_SEND_TO_CUSTOMER_AGENT,
  STATE_DELIVERID_TO_AGENT_IMPOSSIBLE,
  STATE_Deliverd,
  STATE_NOT_PAID,
  STATE_BAJEMOATALE_AGENT,
  STATE_DELIVERY_IMPOSSIBILITY_AGENT,
  STATE_DELIVERID_TO_CUSTOMER,
  STATE_BAJEMOATALE_BRANCH,
};
