import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Typography from "@mui/material/Typography";

const SignaturePad = React.forwardRef((props, ref) => {
  return (
    <div>
      <Typography variant="h6" component="h2" mb={0.2}>
        محل امضا
      </Typography>
      <div style={{ border: "1px solid lightgray", borderRadius: "8px", marginBottom: "1rem" }}>
        <SignatureCanvas canvasProps={{ width: 300, height: 300, className: "sigCanvas" }} ref={ref} />
      </div>
    </div>
  );
});

export default SignaturePad;
