import constantValues from "./constantValues";
const tools = {
  isEmpty,
  toFarsiNumber,
  getValidStates,
  getPhoneNumber,
};
function toFarsiNumber(n) {
  if (n) {
    const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return n.toString().replace(/\d/g, (x) => farsiDigits[x]);
  } else return "";
}
function isEmpty(inputData) {
  return inputData === undefined ||
    inputData === "undefined" ||
    inputData === undefined ||
    inputData === null ||
    inputData === "null" ||
    inputData === "" ||
    inputData === "{}" ||
    inputData.length === 0
    ? true
    : false;
}
function getPhoneNumber(data) {
  var result = null;
  if (data) {
    data = data.replace(",", "");
    data = data.replace("_", "");
    data = data.replace("/", "");
    data = data.replace("؟", "");
    data = data.replace("?", "");
    data = data.replace("+98", "");
    data = data.replace("+", "");
    if (data.length <= 11) {
      result = data;
    } else if (data.length >= 11 && data.startsWith("0")) {
      result = data.substring(0, 11);
    } else if (data.length >= 11 && !data.startsWith("0")) {
      result = "0" + data.substring(0, 10);
    }
    console.log(result);
    return result;
  }
}

function getValidStates(state, accessModel) {
  if (state === constantValues.STATE_SEND_TO_AGENT)
    return [
      {
        value: constantValues.STATE_DELIVERID_TO_AGENT,
        title: "دریافت در نمایندگی",
      },
    ];
  if (state === constantValues.STATE_DELIVERID_TO_AGENT_IMPOSSIBLE) {
    if (isEmpty(accessModel) || isEmpty(accessModel.impossibleAccess) || !accessModel.impossibleAccess) {
      return [
        { value: constantValues.STATE_SEND_TO_AGENT, title: "ارسال به نماینده" },

        {
          value: constantValues.STATE_SEND_TO_CUSTOMER_AGENT,
          title: "ارسال به درب منزل-نماینده",
        },
      ];
    } else {
      return [
        { value: constantValues.STATE_SEND_TO_AGENT, title: "ارسال به نماینده" },

        {
          value: constantValues.STATE_SEND_TO_CUSTOMER_AGENT,
          title: "ارسال به درب منزل-نماینده",
        },
        {
          value: constantValues.STATE_DELIVERY_IMPOSSIBILITY_AGENT,
          title: "عدم امکان تسلیم مرسوله-نماینده",
        },
      ];
    }
  }
  if (state === constantValues.STATE_DELIVERID_TO_AGENT) {
    if (isEmpty(accessModel) || isEmpty(accessModel.impossibleAccess) || !accessModel.impossibleAccess) {
      return [
        {
          value: constantValues.STATE_SEND_TO_CUSTOMER_AGENT,
          title: "ارسال به درب منزل-نماینده",
        },
        {
          value: constantValues.STATE_BAJEMOATALE_AGENT,
          title: "باجه معطله-نمایندگی",
        },
      ];
    } else {
      return [
        {
          value: constantValues.STATE_SEND_TO_CUSTOMER_AGENT,
          title: "ارسال به درب منزل-نماینده",
        },
        {
          value: constantValues.STATE_DELIVERY_IMPOSSIBILITY_AGENT,
          title: "عدم امکان تسلیم مرسوله-نماینده",
        },
        {
          value: constantValues.STATE_BAJEMOATALE_AGENT,
          title: "باجه معطله-نمایندگی",
        },
      ];
    }
  }
  if (state === constantValues.STATE_SEND_TO_CUSTOMER_AGENT) {
    if (isEmpty(accessModel) || isEmpty(accessModel.impossibleAccess) || !accessModel.impossibleAccess) {
      return [
        {
          value: constantValues.STATE_DELIVERID_TO_CUSTOMER,
          title: "تحویل به مشتری",
        },
        {
          value: constantValues.STATE_NOT_PAID,
          title: "تحویل به مشتری - تسویه نشده",
        },
        {
          value: constantValues.STATE_BAJEMOATALE_AGENT,
          title: "باجه معطله-نمایندگی",
        },
      ];
    } else {
      return [
        {
          value: constantValues.STATE_DELIVERID_TO_CUSTOMER,
          title: "تحویل به مشتری",
        },
        {
          value: constantValues.STATE_NOT_PAID,
          title: "تحویل به مشتری - تسویه نشده",
        },
        {
          value: constantValues.STATE_BAJEMOATALE_AGENT,
          title: "باجه معطله-نمایندگی",
        },
        {
          value: constantValues.STATE_DELIVERY_IMPOSSIBILITY_AGENT,
          title: "عدم امکان تسلیم مرسوله-نماینده",
        },
      ];
    }
  }
  if (state === constantValues.STATE_NOT_PAID)
    return [
      {
        value: constantValues.STATE_DELIVERID_TO_CUSTOMER,
        title: "تحویل به مشتری",
      },
    ];
  if (state === constantValues.STATE_DELIVERY_IMPOSSIBILITY_AGENT)
    return [
      {
        value: constantValues.STATE_SEND_TO_CUSTOMER_AGENT,
        title: "ارسال به درب منزل-نماینده",
      },
      {
        value: constantValues.STATE_DELIVERID_TO_CUSTOMER,
        title: "تحویل به مشتری",
      },
    ];
  if (state === constantValues.STATE_BAJEMOATALE_AGENT)
    return [
      {
        value: constantValues.STATE_SEND_TO_CUSTOMER_AGENT,
        title: "ارسال به درب منزل-نماینده",
      },
    ];
  return null;
}
export default tools;
