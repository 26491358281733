import React from "react";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Typography from "@mui/material/Typography";
import { login } from "../../services/registerService";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const Login = () => {
  const [, setCookie] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };
  const handleLogin = async () => {
    const response = await login({ userName, password });

    if (response.data.success == "1") {
      toast.success("خوش آمدید");
      setCookie("jwt", response?.data?.data[0]?.wstoken, {
        expires: expirationDate,
      });
      setCookie("userName", response?.data?.data[0]?.fullName, {
        expires: expirationDate,
      });
      navigate("/");
    } else {
      toast.error(response.data.errorText);
    }
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <div dir='rtl'>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card className='login'>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ marginBottom: 2 }}
                  variant='h5'
                  component='h1'
                >
                  ورود به برنامه
                </Typography>
                <form onSubmit={handleFormSubmit}>
                  <TextField
                    sx={{ marginBottom: 2, direction: "rtl", width: "100%" }}
                    label='نام کاربری'
                    variant='outlined'
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                  <FormControl
                    sx={{ marginBottom: 2, direction: "rtl", width: "100%" }}
                    variant='outlined'
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  >
                    <InputLabel htmlFor='outlined-adornment-password'>
                      رمز عبور
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Password'
                    />
                  </FormControl>
                  <Button
                    type='submit'
                    sx={{ marginBottom: 1, marginTop: 1, width: "100%" }}
                    variant='contained'
                    color='primary'
                    direction='row'
                  >
                    ورود
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Login;
