import agent from "./agent";
export const loadDeliveryInfoPrice = async (data) => {
  try {
    const response = await agent.Order.loadDeliveryInfoPrice(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadCargo = async (data) => {
  try {
    const response = await agent.Order.loadCargo(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadOrder = async (data) => {
  try {
    const response = await agent.Order.loadUserOrder(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadOrderReturn = async (data) => {
  try {
    const response = await agent.Order.loadUserOrderReturn(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadDefaultMessage = async (data) => {
  try {
    const response = await agent.Order.loadDefaultMessage(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadOrderComments = async (data) => {
  try {
    const response = await agent.Order.loadOrderComments(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadEmployeeOrderTotal = async (data) => {
  try {
    const response = await agent.Order.loadEmployeeOrderTotal(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addComment = async (data) => {
  try {
    const response = await agent.Order.addComment(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const uploadFile = async (data) => {
  try {
    const response = await agent.Order.uploadFile(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const loadOrderAttachment = async (data) => {
  try {
    const response = await agent.Order.loadOrderAttachment(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const orderPayment = async (data) => {
  try {
    const response = await agent.Order.orderPayment(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const checkPosTransaction = async (data) => {
  try {
    const response = await agent.Order.checkPosTransaction(data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const changerUserOrderState = async (data) => {
  try {
    const response = await agent.Order.changerUserOrderState(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createReturnCargo = async (data) => {
  try {
    const response = await agent.Order.createReturnCargo(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addOrderToReturnCargo = async (data) => {
  try {
    const response = await agent.Order.addOrderToReturnCargo(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendReturnCargo = async (data) => {
  try {
    const response = await agent.Order.sendReturnCargo(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const loadCargoAttachment = async (data) => {
  try {
    const response = await agent.Order.loadCargoAttachment(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadCargoFile = async (data) => {
  try {
    const response = await agent.Order.uploadCargoFile(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkChangeState = async (data) => {
  try {
    const response = await agent.Order.bulkChangeState(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkAddToReturnCargo = async (data) => {
  try {
    const response = await agent.Order.bulkAddToReturnCargo(data);
    return response;
  } catch (error) {
    throw error;
  }
};