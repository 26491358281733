import React, { useEffect, useState } from "react";
import { loadCargo, sendReturnCargo } from "../../services/orderService";
import { access } from "../../services/registerService";
import { createTheme } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, Stack, Box, Button } from "@mui/material";
import { changeStateByCargo } from "../../services/groupRecieveService";
import { toast } from "react-toastify";
import constantValues from "../../utils/constantValues";
import CargoUploadFileModal from "./CargoUploadFileModal";
import IconButton from "@mui/material/IconButton";
import AttachmentIcon from "@mui/icons-material/Attachment";
import tools from "../../utils/tools";

const CargoInfo = ({ label, value }) => (
  <Typography
    sx={{
      marginBottom: 1,
      alignSelf: "flex-start",
      fontSize: 16,
      color: "rgba(0, 0, 0, 0.8)",
    }}
    variant="body1"
  >
    <strong>{label}:</strong> {value ?? "--"}
  </Typography>
);

const Cargo = () => {
  const [cookies] = useCookies(["jwt"]);
  const token = cookies["jwt"];
  const [cargoList, setCargoList] = useState();
  const [sendCustomerAccess, setSendCustomerAccess] = useState();
  const [loading, setLoading] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(null);
  const badgeStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "50%",
    backgroundColor: "red",
    color: "lightgray",
    width: "18px",
    height: "18px",
  };
  const theme = createTheme({
    direction: "rtl",
  });

  const getOrders = async () => {
    setLoading(true);
    try {
      const response = await loadCargo({
        wstoken: token,
      });

      if (response.data.success === "1") {
        setCargoList(response.data.data);
      }
    } catch (error) {
      console.error("Error while loading cargo", error);
    }
    setLoading(false);
  };

  const getAccess = async () => {
    setLoading(true);
    try {
      const response = await access({
        wstoken: token,
      });
      
      if (response.data.success === "1") {
        setSendCustomerAccess(response.data.data[0].sendCustomerAccess);
      }
    } catch (error) {
      console.error("Error while loading cargo", error);
    }
    getOrders();
  };

  const handleReceiveCargo = async (CargoId, state) => {
    const response = await changeStateByCargo({
      wstoken: token,
      cargoId: CargoId,
      state: state,
    });
    try {
      if (response.data.success === "1") {
        toast.success("عملیات انجام شد");
      } else {
        toast.error(response.data.errorText);
      }
    } catch (error) {
      console.error("Error while loading cargo", error);
    }
  };

  const handleSendReturnCargo = async () => {
    const response = await sendReturnCargo({
      wstoken: token,
    });
    try {
      if (response.data.success === "1") {
        toast.success("کارگو با موفقیت ارسال شد.");
      } else {
        toast.error(response.data.errorText);
      }
    } catch (error) {
      console.error("Error while sending cargo", error);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  const handleOpenFileUpload = (index) => {
    console.log(index);
    setOpenFileUpload(index);
  };
  const bgColor = (cargo) => {
    if (cargo?.cargoType === 5) return "#46607891";
    return "inherit";
  };
  const handleCloseFileUpload = () => setOpenFileUpload(-1);
  return (
    <>
      {!loading ? (
        <>
          {cargoList?.length > 0 &&
            cargoList?.map((cargo, index) => (
              <div key={"cargo_card_" + index}>
                {openFileUpload === index && (
                  <CargoUploadFileModal
                    key={"atta_dialog_" + cargo.id}
                    cargoId={cargo.id}
                    open={openFileUpload === index}
                    setOpen={() => handleCloseFileUpload()}
                  />
                )}
                <Box
                  key={cargo.id}
                  sx={{
                    padding: 2,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    marginX: "2px",
                    marginY: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    background: bgColor(cargo),
                  }}
                >
                  {cargo?.cargoType === 5 && <CargoInfo label="وضعیت" value={cargo?.cargoReturnStateName} />}

                  {cargo?.cargoType !== 5 && (
                    <CargoInfo
                      label="وضعیت"
                      value={cargo?.totalNotSend > 0 ? "دریافت نشده" : cargo?.totalNotSendCustomer > 0 ? "دریافت شده" : "ارسال درب منزل شده"}
                    />
                  )}

                  <CargoInfo label="شماره کارگو" value={cargo?.id} />
                  <CargoInfo label="نام باربری" value={cargo?.freightName} />
                  <CargoInfo label="شماره باربری" value={cargo?.freightPhone} />
                  <CargoInfo label="توضیحات" value={cargo?.description} />
                  {cargo?.cargoType !== 5 && <CargoInfo label="تعداد سفارش" value={cargo?.orderCount} />}
                  {cargo?.cargoType === 5 && <CargoInfo label="تعداد سفارش" value={cargo?.orderCountReturn} />}

                  <CargoInfo label="تاریخ ارسال" value={cargo?.sendDateStr} />
                  {cargo?.cargoType !== 5 && <CargoInfo label="تعداد بسته" value={cargo?.totalPack} />}
                  {cargo?.cargoType === 5 && <CargoInfo label="تعداد بسته" value={cargo?.totalPackReturn} />}

                  <CargoInfo label="تعداد کارتن" value={cargo?.cartonCount} />
                  <CargoInfo label="تعداد گونی" value={cargo?.gunnyCount} />
                  <CargoInfo label="شماره بارنامه" value={cargo?.billNumber} />
                  <CargoInfo label="مبلغ بارنامه" value={cargo?.billCost} />
                  <CargoInfo label="نوع کارگو" value={cargo?.cargoTypeStr} />
                  <Stack
                    my={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {cargo?.cargoType === 5 && cargo?.state === 0 && (
                      <Button style={{ margin: "5px" }} mb={1} onClick={() => handleSendReturnCargo()} variant="contained" color="success" fullWidth>
                        ارسال کارگو برگشتی
                      </Button>
                    )}
                    {cargo?.cargoType !== 5 && (
                      <Button
                        style={{ margin: "5px" }}
                        mb={1}
                        onClick={() => handleReceiveCargo(cargo?.id, constantValues.STATE_DELIVERID_TO_AGENT)}
                        variant="contained"
                        color="success"
                        fullWidth
                      >
                        دریافت سفارشات
                      </Button>
                    )}
                    {cargo?.cargoType !== 5 && sendCustomerAccess !== null && sendCustomerAccess !== undefined && sendCustomerAccess && (
                      <Button
                        ml={1}
                        style={{ margin: "5px" }}
                        fullWidth
                        onClick={() => handleReceiveCargo(cargo?.id, constantValues.STATE_SEND_TO_CUSTOMER_AGENT)}
                        variant="contained"
                        color="success"
                      >
                        ارسال درب منزل
                      </Button>
                    )}
                  </Stack>
                  <IconButton
                    style={{ position: "absolute", left: "0", marginLeft: "35px" }}
                    onClick={() => handleOpenFileUpload(index)}
                    aria-label="add to favorites"
                  >
                    <AttachmentIcon />
                    {cargo?.attachCount ? <Typography sx={badgeStyle}>{tools.toFarsiNumber(cargo.attachCount)}</Typography> : ""}
                  </IconButton>
                </Box>
              </div>
            ))}
        </>
      ) : (
        <Stack
          my={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography my={2} variant="h5" component="h2" align="center">
            درحال بارگیری ...
          </Typography>
        </Stack>
      )}
      {cargoList?.length === 0 && !loading ? (
        <Stack
          my={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography my={2} variant="h5" component="h2" align="center">
            موردی یافت نشد
          </Typography>
        </Stack>
      ) : (
        ""
      )}
    </>
  );
};

export default Cargo;
