const routes = {
  HOME: "/",
  CARGO:"/cargo",
  FINANCE:"/finance",
  ORDERS: "/orders",
  RETURNS: "/returns",
  LOGIN: "/login",
  DETAILS: "/details",
  BULKCHANGE: "/bulkChange",
};

export default routes;
