import { useEffect, useState } from "react";
import { loadEmployeeOrderTotal } from "../../services/orderService";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useCookies } from "react-cookie";
import tools from "../../utils/tools";
import CircularProgress from '@mui/material/CircularProgress';

const Home = () => {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const token = cookies["jwt"];
  const date = new Date();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [value,] = useState(date);
 
  const titles = {
    inCityCount: 'موجود در انبار',
    noPaid: 'مبلغ سفارش های تسویه نشده',
    sendToCityCount: 'ارسال شده به شهر',
    sendToCustomerCount: 'ارسال شده درب منزل',
    imposibleCount: 'عدم امکان تسلیم مرسوله',
    bajeMoataleCount: 'باجه معطله',
    noPaidCount: 'تسویه نشده',
    totalOrder: ' کل سفارش ها'
  };

  const getOrders = async () => {
    setLoading(true);

    const response = await loadEmployeeOrderTotal({
      wstoken: token,
    });
    try {
      setLoading(true);

      if (response.data.success == "1") {
        setData(response.data.data[0]);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrders();
  }, [value]);

  return (
    <div style={{ paddingBottom: "6rem" }}>
      {data && !loading ? (
        <>
           {Object.entries(data).map(([key, value]) => (
                 <Card  key={key} sx={{ padding: "1.5rem", marginTop: "1rem" }}>
                 <Stack
                   direction='row'
                   justifyContent='space-between'
                   alignItems='center'
                   spacing={2}
                 >
                   <p>{titles[key]}</p> 
                   <p>{tools.toFarsiNumber(value.toLocaleString())}{key === "noPaid"? " ریال":""}</p>
                 </Stack>
               </Card>
           ))}
        </>
      ) : (
        <Stack  
         my={4} 
         sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }} >
          <CircularProgress />
        <Typography
          my={2}
          variant='h5'
          component='h2'
          align="center"
        >
          درحال بارگیری ...
        </Typography>
        </Stack>
      )}
    </div>
  );
};
export default Home;
