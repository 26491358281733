import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function BarcodeSkeleton() {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        height:300,
        p: 2,
      };
  return (
    <Stack sx={style} zIndex={-40} spacing={2}>
      <Skeleton width={"100%"} height={300} />
    </Stack>
  );
}
