import React, { useEffect, useState, useRef } from "react";
import Search from "./components/Search";
import Card from "./components/Card";
import CardSkeleton from "./components/skeletons/CardSkeleton";
import { loadOrder } from "../../services/orderService";
import Typography from "@mui/material/Typography";
import FIlterSelectBox from "./components/FIlterSelectBox";
import { useCookies } from "react-cookie";
import { DataScroller } from "primereact/datascroller";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { access } from "../../services/registerService";
const Orders = () => {
  let defaultLazyParam = { first: 0, rows: 20, searchQuery: null, showDeliverToCustomer: false };
  const ds = useRef(null);
  const [lazyParam, setLazyParam] = useState(defaultLazyParam);
  const [cookies] = useCookies(["jwt"]);
  const token = cookies["jwt"];
  const [endOfList, setEndOfList] = React.useState(false);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = React.useState("");
  const [showDeliverToCustomer, setShowDeliverToCustomer] = useState(false);
  const [accessModel, setAccessModel] = useState();

  useEffect(() => {
    if (lazyParam === null || undefined === lazyParam) return;
    if (endOfList) return;
    async function fetchData() {
      setLoading(true);
      const response = await loadOrder({
        first: lazyParam?.first,
        size: lazyParam?.rows,
        state: filterState === "" || undefined === filterState ? null : filterState,
        wstoken: token,
        searchQuery: lazyParam?.searchQuery,
        showDeliverToCustomer: showDeliverToCustomer === "" || undefined === showDeliverToCustomer ? false : showDeliverToCustomer,
      });

      try {
        if (response.data.success === "1") {
          if (response.data.data.length > 0) {
            setOrderList((prev) => {
              const updatedList = [...prev];
              response.data.data.forEach((item) => {
                // Check if the item already exists in the orderList
                const itemExists = prev.some((prevItem) => prevItem.id === item.id);
                if (!itemExists) {
                  updatedList.push(item);
                }
              });
              return updatedList;
            });
          } else {
            setEndOfList(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    fetchData();
  }, [lazyParam, filterState, showDeliverToCustomer]);

  useEffect(() => {
    const getAccess = async () => {
      try {
        const response = await access({
          wstoken: token,
        });
        
        if (response.data.success === "1") {
          setAccessModel(response.data.data[0]);
        }
      } catch (error) {
        console.error("Error while loading User Access", error);
      }
    };
    getAccess();
  }, []);

  const FilterHandleChange = (event) => {
    setEndOfList(false);
    setOrderList([]);
    setFilterState(event.target.value);
  };
  const ShowDeliverToCustomerHandleChange = (event) => {
    setEndOfList(false);
    setOrderList([]);
    setShowDeliverToCustomer(event.target.checked); // Use event.target.checked to get the boolean value
  };

  const afterSearchComplete = (searchQuery) => {
    setEndOfList(false);
    setOrderList([]);
    setLazyParam({ first: 0, rows: 20, searchQuery: searchQuery, showDeliverToCustomer: showDeliverToCustomer });
  };
  const afterChangeState = (selectedState) => {
    setEndOfList(false);
    setOrderList([]);
    setLazyParam({ first: 0, rows: 20, searchQuery: null, showDeliverToCustomer: showDeliverToCustomer });
  };

  const loadMore = () => {
    let newFirst = 0;
    if (lazyParam !== null && undefined !== lazyParam) newFirst = lazyParam.first + lazyParam.rows;
    setLazyParam({ first: newFirst, rows: 20, searchQuery: null, showDeliverToCustomer: showDeliverToCustomer });
  };

  const itemTemplate = (order) => {
    return (
      <>
        <div key={order.orderSerial}>
          <Card afterChangeState={afterChangeState} order={order} isReturn={false} accessModel={accessModel}/>
        </div>
      </>
    );
  };
  const emptyMessageTemplate = () => {
    // Custom empty message template
    return (
      <>
        {loading ? (
          <CardSkeleton />
        ) : (
          <Typography variant={"h5"} align="center" m={4}>
            هیچ سفارشی یافت نشد
          </Typography>
        )}
      </>
    );
  };
  const footer = (
    <>
      {!endOfList ? (
        <Button onClick={loadMore} variant="outlined">
          مشاهده سفارشات بیشتر
        </Button>
      ) : (
        ""
      )}
    </>
  );
  return (
    <>
      <Search afterSearchComplete={afterSearchComplete} />
      <FIlterSelectBox state={filterState} handleChange={FilterHandleChange} />
      <FormGroup>
        <FormControlLabel
          onChange={ShowDeliverToCustomerHandleChange}
          value={showDeliverToCustomer}
          labelPlacement="end"
          control={<Checkbox />}
          label="نمایش سفارشات تحویلی"
        />
      </FormGroup>
      <hr/>
      <div style={{ paddingBottom: "6rem" }}>
        <DataScroller
          ref={ds}
          value={orderList}
          itemTemplate={itemTemplate}
          //buffer={0.4}
          rows={20}
          //lazy
          //onLazyLoad={loadData}
          loader={loading}
          footer={footer}
          emptyMessage={emptyMessageTemplate}
        />
      </div>
    </>
  );
};

export default Orders;
