import routes from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const ProtectedRoute = ({ children }) => {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key

  const token = cookies["jwt"];
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(routes.LOGIN);
    }
  }, [token]);
  return children;
};
export default ProtectedRoute;
