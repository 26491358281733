import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import PersonIcon from "@mui/icons-material/Person";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CommentIcon from "@mui/icons-material/Comment";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CommentModal from "./CommentModal";
import UploadFileModal from "./UploadFileModal";
import DeliverToCustomerModal from "./DeliverToCustomerModal";
import tools from "../../../utils/tools";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { orderPayment, changerUserOrderState, addOrderToReturnCargo, checkPosTransaction } from "../../../services/orderService";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import constantValues from "../../../utils/constantValues";
import LoadingOverlay from "../../../components/Loading";
import { CalendarIcon } from "@mui/x-date-pickers";
export default function RecipeReviewCard({ afterChangeState, order, isReturn, accessModel }) {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const token = cookies["jwt"];
  const [loading, setLoading] = useState(false);
  const [openComments, setOpenComments] = React.useState(false);
  const [openFileUpload, setOpenFileUpload] = React.useState(false);
  const [nextState, setNextState] = React.useState(false);
  const [openDeliverToCustomerModal, setOpenDeliverToCustomerModal] = React.useState(false);
  const badgeStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "50%",
    backgroundColor: "red",
    color: "lightgray",
    width: "18px",
    height: "18px",
  };
  const handleChange = async (event) => {
    setNextState(event.target.value);
    if (event.target.value === 9 || event.target.value === 10) {
      setOpenDeliverToCustomerModal(true);
    } else {
      const response = await changerUserOrderState({
        orderSerial: order.orderSerial,
        wstoken: token,
        state: event.target.value,
      });
      try {
        if (response.data.success === "1") {
          afterChangeState(event.target.value);
          toast.success("تغییر وضعیت با موفقیت انجام شد");
        } else {
          toast.error(response.data.errorText);
        }
      } catch {
        toast.error("خطا در تغییر وضعیت");
      }
    }
  };
  const handleOpen = () => setOpenComments(true);
  const handleOpenFileUpload = () => setOpenFileUpload(true);

  const handlePayment = async () => {
    setLoading(true);
    const response = await orderPayment({
      orderSerial: order.orderSerial,
      wstoken: token,
    });
    try {
      if (response.data.success === "1") {
        toast.success("پرداخت با موفقیت انجام شد");
      } else {
        toast.error(response.data.errorText);
      }
    } catch {
      setLoading(false);
      toast.error(response.data.errorText);
    }
    setLoading(false);
  };

  const handleCheckPosTransaction = async () => {
    setLoading(false);
    const response = await checkPosTransaction({
      orderSerial: order.orderSerial,
      wstoken: token,
    });
    try {
      if (response.data.success === "1") {
        toast.success("پرداخت با موفقیت انجام شد");
      } else {
        toast.error(response.data.errorText);
      }
    } catch {
      toast.error(response.data.errorText);
    }
  };

  const addToReturnCargo = async () => {
    setLoading(true);
    const response = await addOrderToReturnCargo({
      orderSerial: order.orderSerial,
      wstoken: token,
    });
    try {
      if (response.data.success === "1") {
        afterChangeState(null);
        toast.success("سفارش با موفقیت به کارگو اضافه شد.");
      } else {
        toast.error(response.data.errorText);
      }
    } catch {
      setLoading(false);
      toast.error(response.data.errorText);
    }
    setLoading(false);
  };
  return (
    <div>
      {openComments && <CommentModal orderCode={order.orderSerial} open={openComments} setOpen={() => setOpenComments()} />}
      {openFileUpload && <UploadFileModal orderSerial={order.orderSerial} open={openFileUpload} setOpen={() => setOpenFileUpload()} />}
      {openDeliverToCustomerModal && (
        <DeliverToCustomerModal
          orderSerial={order.orderSerial}
          order={order}
          nextState={nextState}
          open={openDeliverToCustomerModal}
          setOpen={() => setOpenDeliverToCustomerModal()}
          afterChangeState={() => {
            afterChangeState();
          }}
        />
      )}
      {loading && <LoadingOverlay loading={loading} />}
      <Card sx={{ marginBottom: "1rem" }}>
        <Stack mx={2} direction="row" justifyContent="space-between" alignItems="center">
          <div style={{ padding: "1rem 0 0 0" }}>
            <Typography sx={{ marginBottom: "-5px" }} variant="h4" gutterBottom>
              {tools.toFarsiNumber(order.orderSerial)}
            </Typography>
            <Typography variant="h6" gutterBottom>
              <PersonIcon sx={{ marginBottom: "-5px" }} />
              {order.supplierName}/{tools.toFarsiNumber(order.supplierOrderCode)}
            </Typography>
          </div>
          <Stack direction="column" alignItems="center">
            <Chip label={order.state} color="success" />
            {isReturn && <Chip style={{ margin: "2px" }} label={order.recursiveStateName} color="warning" />}
            {!isReturn && <Chip style={{ margin: "2px" }} label={order.paymentMethod} color="warning" />}
          </Stack>
        </Stack>

        <CardContent style={{ padding: "5px" }}>
          <Stack mb={1} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <HomeIcon />
              <Typography px={1}>
                {order.cityName}/{order.customerAddress}
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={1} direction="row" justifyContent="space-between" alignItems="center">
            {!isReturn && order.stateCode === constantValues.STATE_SEND_TO_CUSTOMER_AGENT && order.paymentMethodCode === 1 && (
              <Button onClick={() => handlePayment()} variant="contained" color="success">
                پرداخت
              </Button>
            )}
            {!isReturn && order.stateCode === constantValues.STATE_SEND_TO_CUSTOMER_AGENT && order.paymentMethodCode === 1 && (
              <Button onClick={() => handleCheckPosTransaction()} variant="contained" color="success">
                استعلام تراکنش دستگاه
              </Button>
            )}
          </Stack>

          {!isReturn && (
            <div>
              <FormControl sx={{ minWidth: "100%", marginBottom: "1rem" }}>
                <Select
                  sx={{
                    "& .MuiSelect-select": {
                      paddingRight: 4,
                      paddingLeft: 2,
                      paddingTop: 1,
                      paddingBottom: 1,
                    },
                  }}
                  value=""
                  onChange={handleChange}
                  displayEmpty
                  inputprops={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>تغییر وضعیت</em>
                  </MenuItem>
                  {!tools.isEmpty(order.stateCode) &&
                    tools.getValidStates(order.stateCode,accessModel)?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          )}

          {isReturn && tools.isEmpty(order?.cargoReturnId) && (
            <div style={{ paddingBottom: "1rem" }}>
              <Button onClick={() => addToReturnCargo()} variant="contained" color="success">
                افزودن به کارگو برگشتی
              </Button>
            </div>
          )}

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>اطلاعات بیشتر</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {isReturn && (
                <Stack sx={{ marginBottom: "0.5rem" }} direction="row" alignItems="center">
                  <LocalShippingIcon />
                  <Typography px={1}>{order.cargoReturnId}</Typography>
                </Stack>
              )}

              <Stack sx={{ marginBottom: "0.5rem" }} direction="row" alignItems="center">
                <CreditCardIcon />

                <Typography px={1}>{order.paymentMethod}</Typography>
                <Typography px={1}>{order?.paymentCost > 0 ? ` / ${tools.toFarsiNumber(order?.paymentCost?.toLocaleString())} ریال` : ""}</Typography>
              </Stack>
              <Stack sx={{ marginBottom: "0.5rem" }} direction="row" alignItems="center">
                <PersonIcon />
                <Typography px={1}> {order.customerName} </Typography>
              </Stack>
              <Stack sx={{ marginBottom: "0.5rem" }} direction="row" alignItems="center">
                <PhoneIcon />
                <a href={`tel:${tools.getPhoneNumber(order.customerPhone)}`}>
                  <Typography px={1}>{tools.toFarsiNumber(order.customerPhone)}</Typography>
                </a>
              </Stack>
              <Stack sx={{ marginBottom: "0.5rem" }} direction="row" alignItems="center">
                <HomeIcon />
                <Typography px={1}>
                  {order.cityName}/{order.customerAddress}
                </Typography>
              </Stack>
              <Stack sx={{ marginBottom: "0.5rem" }} direction="row" alignItems="center">
                <CalendarIcon />
                <Typography px={1}> {order.toProvince} </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </CardContent>
        <CardActions>
          <IconButton onClick={handleOpenFileUpload} aria-label="add to favorites">
            <AttachmentIcon />
            {order.attachmentsCount ? <Typography sx={badgeStyle}>{tools.toFarsiNumber(order.attachmentsCount)}</Typography> : ""}
          </IconButton>

          <IconButton sx={{ position: "relative" }} onClick={handleOpen} aria-label="share">
            <CommentIcon />
            {order.commentsCount ? <Typography sx={badgeStyle}>{tools.toFarsiNumber(order.commentsCount)}</Typography> : ""}
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
}
