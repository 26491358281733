import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import constantValues from "../../../utils/constantValues";

export default function FIlterSelectBox({ state, handleChange }) {
  return (
    <FormControl sx={{ minWidth: "100%", marginBottom: "0.4rem" }}>
      <Select
        sx={{
          "& .MuiSelect-select": {
            paddingRight: 4,
            paddingLeft: 2,
            paddingTop: 1,
            paddingBottom: 1,
          },
        }}
        value={state}
        onChange={handleChange}
        displayEmpty
        inputprops={{ "aria-label": "Without label" }}
      >
        <MenuItem value="">
          <em>فیلتر بر اساس وضعیت</em>
        </MenuItem>
        <MenuItem value={constantValues.STATE_SEND_TO_AGENT}>ارسال به نماینده</MenuItem>
        <MenuItem value={constantValues.STATE_DELIVERID_TO_AGENT}>دریافت در نمایندگی</MenuItem>
        <MenuItem value={constantValues.STATE_SEND_TO_CUSTOMER_AGENT}>ارسال به درب منزل-نماینده</MenuItem>
        <MenuItem value={constantValues.STATE_DELIVERID_TO_AGENT_IMPOSSIBLE}>دریافت در نمایندگی-درخواست ارسال مجدد</MenuItem>
        <MenuItem value={constantValues.STATE_DELIVERY_IMPOSSIBILITY_AGENT}>عدم امکان تسلیم مرسوله-نماینده</MenuItem>
        <MenuItem value={constantValues.STATE_NOT_PAID}>تحویل به مشتری - تسویه نشده</MenuItem>
        <MenuItem value={constantValues.STATE_BAJEMOATALE_AGENT}>باجه معطله-نمایندگی</MenuItem>
      </Select>
    </FormControl>
  );
}
