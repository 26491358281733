import { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import CommetSkeleton from "../orders/components/skeletons/CommentSkeleton";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import {
  uploadCargoFile,
  loadCargoAttachment,
} from "../../services/orderService";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif,",
  "image/*",
  "application/doc",
  "application/docx",
  "application/pdf",
];

const CargoUploadFileModal = ({ open, setOpen, cargoId }) => {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const token = cookies["jwt"];
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previousattachments, setPreviousAttachments] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
  };
  const getCargoAttachments = async () => {
    const response = await loadCargoAttachment({
      cargoId: cargoId,
      wstoken: token,
    });
    try {
      setLoading(true);

      if (response.data.success == "1") {
        setPreviousAttachments(response.data.data);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("نوع فایل نامعتبر است.");
      return;
    }

    setSelectedFile(file);
    setError(null);
  };
  const handleClose = () => setOpen();

  const handleUpload = async () => {
    if (selectedFile) {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("cargoId", cargoId);
      formData.append("wstoken", token);
      const response = await uploadCargoFile(formData);

      try {
        if (response?.data.success == "1") {
          toast.success("فایل با موفقیت بارگزاری شد");
          handleClose();
          setLoading(false);
        }
      } catch {
        toast.error("خطا در بارگزاری فایل");
        setLoading(false);
      }
    } else {
      toast.error("لطفا فایل مورد نظر را انتخاب کنید");
      setLoading(false);
    }
  };
  useEffect(() => {
    getCargoAttachments();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={style}
        p={3}
        border='1px dashed #ccc'
        borderRadius={8}
        textAlign='center'
      >
        <input
          type='file'
          accept='image/* ,.jpg, .jpeg,.png,.doc,.docx,.pdf'
          onChange={handleFileChange}
          style={{ display: "none" }}
          id='image-file-input'
        />
        <label htmlFor='image-file-input'>
          <Button variant='outlined' component='span'>
            انتخاب فایل
          </Button>
        </label>

        <div>
          <Typography variant='subtitle1' m={2}>
            فایل انتخاب شده : {selectedFile?.name ? selectedFile.name : "___"}
          </Typography>
          <Stack
            direction='row-reverse'
            justifyContent='center'
            alignItems='center'
          >
            <Button
              disabled={selectedFile == null || loading}
              variant='contained'
              color='primary'
              onClick={handleUpload}
              mt={2}
            >
              {!loading ? " بارگذاری فایل" : "درحال بارگذاری"}
            </Button>
            <Button
              sx={{ margin: "0 5px 0 5px" }}
              onClick={() => handleClose()}
              variant='outlined'
              color='error'
            >
              لغو/بستن
            </Button>
          </Stack>
        </div>

        {error && (
          <Typography variant='body2' color='error' mt={2}>
            {error}
          </Typography>
        )}
        <Typography mt={2}>فایل های قبلی</Typography>
        <Box
          sx={{
            border: "1px solid gray",
            borderRadius: "8px",
            height: "10rem",
            overflowY: "auto",
            margin: "1rem 0",
            padding: "0.5rem",
          }}
        >
          {previousattachments ? (
            previousattachments?.map((previousattachment, index) => {
              return (
                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "#f0f1f2",
                    padding: "0.5rem",
                    margin: "1rem 0",
                  }}
                  key={index}
                >
                  <Typography
                    sx={{
                      textAlign: "right",
                      alignItems: "flex-end",
                      color: "#525151",
                      fontSize: "16px",
                    }}
                  >
                    {previousattachment.username}
                  </Typography>

                  <Typography my={1}>{previousattachment.fileName}</Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                      alignItems: "flex-start",
                      color: "#525151",
                      fontSize: "12px",
                    }}
                  >
                    {previousattachment.registerDateStr}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <CommetSkeleton />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CargoUploadFileModal;
