const deliveryInfoPrice = {
  getTotalPay,
  getTotalImposibleCountDay,
  getTotalInventoryCountDay,
  getTotalCountDay,
  getTotalImposiblePriceDay,
  getTotalInventoryPriceDay,
  getTotalPriceDay,
  getTotalInventoryCountMonth,
  getTotalImposibleCountMonth,
  getTotalCountMonth,
  getTotalInventoryPriceMonth,
  getTotalPriceMonth,
};
function getTotalPay(data) {
  return (
    getTotalInventoryPriceMonth(data) +
    getTotalImposiblePriceMonth(data) +
    getTotalPriceMonth(data)
    // getStaticPrice(data)
  );
}

function getTotalImposibleCountDay(data) {
  return (
    data.imposibleNormalCountDay +
    data.imposibleVIPCountDay +
    data.imposibleDangCountDay +
    data.imposiblenewshaCountDay
  );
}

function getTotalInventoryCountDay(data) {
  return (
    data.inventoryNormalCountDay +
    data.inventoryVIPCountDay +
    data.inventoryDangCountDay +
    data.inventorynewshaCountDay
  );
}

function getTotalCountDay(data) {
  return (
    data.nedexNormalCountDay +
    data.nedexVIPCountDay +
    data.nedexDangCountDay +
    data.newshaCountDay +
    data.nedexNormalCODCountDay +
    data.nedexVIPCODCountDay +
    data.nedexDangCODCountDay +
    data.newshaCODCountDay
  );
}

function getTotalImposiblePriceDay(data) {
  return (
    data.imposibleNormalPriceDay +
    data.imposibleVIPPriceDay +
    data.imposibleDangPriceDay +
    data.imposiblenewshaPriceDay
  );
}

function getTotalInventoryPriceDay(data) {
  return (
    data.inventoryNormalPriceDay +
    data.inventoryVIPPriceDay +
    data.inventoryDangPriceDay +
    data.inventorynewshaPriceDay
  );
}

function getTotalPriceDay(data) {
  return (
    data.nedexNormalPriceDay +
    data.nedexVIPPriceDay +
    data.nedexDangPriceDay +
    data.newshaPriceDay +
    data.nedexNormalCODPriceDay +
    data.nedexVIPCODPriceDay +
    data.nedexDangCODPriceDay +
    data.newshaCODPriceDay
  );
}

function getTotalInventoryCountMonth(data) {
  return (
    data.inventoryNormalCountMonth +
    data.inventoryVIPCountMonth +
    data.inventoryDangCountMonth +
    data.inventorynewshaCountMonth
  );
}

function getTotalImposibleCountMonth(data) {
  return (
    data.imposibleNormalCountMonth +
    data.imposibleVIPCountMonth +
    data.imposibleDangCountMonth +
    data.imposiblenewshaCountMonth
  );
}

function getTotalCountMonth(data) {
  return (
    data.nedexNormalCountMonth +
    data.nedexVIPCountMonth +
    data.nedexDangCountMonth +
    data.newshaCountMonth +
    data.nedexNormalCODCountMonth +
    data.nedexVIPCODCountMonth +
    data.nedexDangCODCountMonth +
    data.newshaCODCountMonth
  );
}

function getTotalInventoryPriceMonth(data) {
  return (
    data.inventoryNormalPriceMonth +
    data.inventoryVIPPriceMonth +
    data.inventoryDangPriceMonth +
    data.inventorynewshaPriceMonth
  );
}

function getTotalImposiblePriceMonth(data) {
  return (
    data.imposibleNormalPriceMonth +
    data.imposibleVIPPriceMonth +
    data.imposibleDangPriceMonth +
    data.imposiblenewshaPriceMonth
  );
}

function getTotalPriceMonth(data) {
  return (
    data.nedexNormalPriceMonth +
    data.nedexVIPPriceMonth +
    data.nedexDangPriceMonth +
    data.newshaPriceMonth +
    data.nedexNormalCODPriceMonth +
    data.nedexVIPCODPriceMonth +
    data.nedexDangCODPriceMonth +
    data.newshaCODPriceMonth
  );
}
export default deliveryInfoPrice;
