import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import tools from "../utils/tools";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Navbar() {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const [userNameCookie] = useCookies(["userName"]); // Use 'jwt' as the cookie key
  const token = cookies["jwt"];
  const userName = userNameCookie["userName"];

  const handleLogout = () => {
    navigate("/login");
    removeCookie("jwt");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar sx={{ backgroundColor: "white", color: "black" }}>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
           {userName ? userName :''} 
          </Typography>
          {tools.isEmpty(token) ? (
            <a href='/login'>
              <Button color='inherit'>ورود</Button>
            </a>
          ) : (
            <Button onClick={() => handleLogout()} color='inherit'>
              خروج
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
