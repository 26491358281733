import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Variants({ index }) {
  return (
    <div className='shadow' style={{ margin: "0.5rem 0" }} key={index}>
      <Stack
        sx={{
          bgcolor: "white",
          padding: "1rem",
          borderRadius: "5px",
        }}
        spacing={1.5}
      >
        <Skeleton variant='rounded' width={140} height={30} />
        <Skeleton variant='rounded' width={140} height={30} />

        <Skeleton
          sx={{ borderRadius: "30px" }}
          variant='rounded'
          width={150}
          height={28}
        />
        <Skeleton variant='rounded' width={"100%"} height={60} />
        <Skeleton variant='rounded' width={"100%"} height={60} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            margin: "1.7rem 0 0 0",
          }}
        >
          <Skeleton variant='circular' width={40} height={40} />
          <Skeleton
            sx={{ margin: "0 0.5rem" }}
            variant='circular'
            width={40}
            height={40}
          />
        </div>
      </Stack>
    </div>
  );
}
