import React, { useState } from "react";
import Search from "./components/Search";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { bulkAddToReturnCargo, bulkChangeState } from "../../services/orderService";
import tools from "../../utils/tools";
import { toast } from "react-toastify";

const BulkChange = () => {
  const [cookies] = useCookies(["jwt"]);
  const token = cookies["jwt"];
  const [orders, setOrders] = useState([]);

  const afterSearchComplete = (searchQuery) => {
    if (!tools.isEmpty(searchQuery)) addOrderToList(searchQuery);
  };

  const addOrderToList = (serial) => {
    if (orders.length > 0) {
      for (const order of orders) {
        if (order === serial) return;
      }
    }
    setOrders([...orders, serial]);
  };

  const deleteFromOrderList = (selectedSerial) => {
    setOrders((orders) => {
      return orders.filter((serial) => serial !== selectedSerial);
    });
  };

  const changeBulkState = async (state) => {
    const response = await bulkChangeState({
      wstoken: token,
      state: state,
      orderSerials: orders,
    });

    try {
      if (response.data.success === "1") {
        setOrders([]);
        toast.success("تغییر وضعیت با موفقیت انجام شد");
      } else {
        toast.error(response.data.errorText);
      }
    } catch (err) {
      toast.error("خطا در تغییر وضعیت");
      console.log(err);
    }
  };

  const addBulkToReturnCargo = async () => {
    const response = await bulkAddToReturnCargo({
      wstoken: token,
      orderSerials: orders,
    });

    try {
      if (response.data.success === "1") {
        setOrders([]);
        toast.success("سفارشات با موفقیت اضافه شدند.");
      } else {
        toast.error(response.data.errorText);
      }
    } catch (err) {
      toast.error("خطا در تغییر وضعیت");
      console.log(err);
    }
  };

  return (
    <>
      <Search afterSearchComplete={afterSearchComplete} />
      <div style={{ paddingBottom: "1rem", display: "flex", flexDirection: "column" }}>
        <Button onClick={() => changeBulkState(16)} variant="outlined" style={{ margin: "2px" }}>
          دریافت سفارش
        </Button>
        <Button onClick={() => changeBulkState(18)} variant="outlined" style={{ margin: "2px" }}>
          ارسال درب منزل
        </Button>
        <Button onClick={() => addBulkToReturnCargo()} variant="outlined" style={{ margin: "2px" }}>
          افزودن به کارگوی برگشتی
        </Button>
      </div>
      <div style={{ paddingBottom: "6rem" }}>
        {orders?.length > 0 &&
          orders.map((serial, index) => (
            <div key={serial}>
              <Card sx={{ marginBottom: "1rem" }}>
                <CardContent>
                  <Stack mx={2} direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ padding: "1rem 0 0 0" }}>
                      <Typography sx={{ marginBottom: "-5px" }} variant="h4" gutterBottom>
                        شماره سفارش
                      </Typography>
                      <Typography sx={{ marginBottom: "-5px" }} variant="h4" gutterBottom>
                        {tools.toFarsiNumber(serial)}
                      </Typography>
                    </div>
                  </Stack>
                </CardContent>
                <CardActions>
                  <Button onClick={() => deleteFromOrderList(serial)} size="small">
                    حذف
                  </Button>
                </CardActions>
              </Card>
            </div>
          ))}
      </div>
    </>
  );
};

export default BulkChange;
