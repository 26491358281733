import React, { useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import TextField from "@mui/material/TextField";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { changerUserOrderState, uploadFile } from "../../../services/orderService";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-jalaali";
import SignaturePad from "./SignaturePad";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};
export function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}
const DeliverToCustomerModal = ({ open, setOpen, order, afterChangeState,nextState }) => {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key
  const token = cookies["jwt"];
  const [receiptNumber, setReceiptNumber] = React.useState();
  const [receiptDate, setReceiptDate] = React.useState(new Date());
  const sigPad = useRef(null);

  const theme = createTheme({
    direction: "rtl", // Both here and <body dir="rtl">
  });
  function base64ToBlob(base64Data, contentType) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
  const handleClose = () => setOpen(false);
  const trimSignature = async () => {
    const isEmpty = sigPad.current.isEmpty();
    const trimmedCanvas = sigPad.current.getTrimmedCanvas();
    if (order.paymentMethod === "COD" && nextState === 9) {
      if (!isEmpty && trimmedCanvas && receiptNumber && receiptDate) {
        const formData = new FormData();
        const dataURL = trimmedCanvas.toDataURL("image/png");
        const base64Data = dataURL.split(",")[1];
        const file = base64ToBlob(base64Data, "image/png");

        formData.append("file", file);
        formData.append("orderId", order.orderSerial);
        formData.append("wstoken", token);
        const response = await uploadFile(formData);
        try {
          if (response?.data.success === "1") {
            // toast.success("فایل با موفقیت بارگزاری شد");
            handleClose();
          }
        } catch {
          toast.error("خطا در بارگزاری امضا");
        }
      } else {
        // toast.error("لطفا فایل مورد نظر را انتخاب کنید");
      }
    } else {
      if (!isEmpty && trimmedCanvas) {
        const formData = new FormData();
        const dataURL = trimmedCanvas.toDataURL("image/png");
        const base64Data = dataURL.split(",")[1];
        const file = base64ToBlob(base64Data, "image/png");

        formData.append("file", file);
        formData.append("orderId", order.orderSerial);
        formData.append("wstoken", token);
        const response = await uploadFile(formData);
        try {
          if (response?.data.success === "1") {
            // toast.success("فایل با موفقیت بارگزاری شد");
            handleClose();
          }
        } catch {
          toast.error("خطا در بارگزاری امضا");
        }
      } else {
        // toast.error("لطفا فایل مورد نظر را انتخاب کنید");
      }
    }
  };
  const clear = () => {
    sigPad.current.clear();
  };
  const submitForm = async () => {
    trimSignature();

    if (order.paymentMethod === "COD" && nextState === 9) {
      if (receiptNumber && receiptDate) {
        const response = await changerUserOrderState({
          orderSerial: order.orderSerial,
          wstoken: token,
          state: nextState,
          bankId: 224,
          receiptNumber: receiptNumber,
          receiptDate: moment(receiptDate).format("jYYYY/jMM/jDD"),
        });
        try {
          if (response.data.success === "1") {
            afterChangeState(nextState);
            toast.success("تغییر وضعیت با موفقیت انجام شد");
          } else {
            toast.error(response.data.errorText);
          }
        } catch {
          toast.error("خطا در تغییر وضعیت");
        }
        handleClose();
      } else {
        toast.error("لطفا فیلد های مورد نظر را تکمیل نمایید");
      }
    } else {
      const response = await changerUserOrderState({
        orderSerial: order.orderSerial,
        wstoken: token,
        state: nextState,
      });
      try {
        if (response.data.success === "1") {
          afterChangeState(nextState);
          toast.success("تغییر وضعیت با موفقیت انجام شد");
        } else {
          toast.error(response.data.errorText);
        }
      } catch {
        toast.error("خطا در تغییر وضعیت");
      }
      handleClose();
    }

    handleClose();
  };
  return (
    <>
      <div>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            {order.paymentMethod === "COD" && nextState ===9? (
              <RTL>
                <ThemeProvider theme={theme}>
                  <div dir="rtl">
                    <LocalizationProvider dir="ltr" dateAdapter={AdapterDateFnsJalali}>
                      <Typography mb={0.5} variant="h6" component="h2">
                        فیلتر تاریخ
                      </Typography>
                      <MobileDatePicker
                        sx={{
                          width: "100%",
                          backgroundColor: "white",
                        }}
                        value={receiptDate}
                        onChange={(newValue) => setReceiptDate(newValue)}
                      />
                    </LocalizationProvider>
                  </div>
                </ThemeProvider>
              </RTL>
            ) : (
              ""
            )}
            {order.paymentMethod === "COD" && nextState ===9 ? (
              <>
                <Typography variant="h6" component="h2" mt={2} mb={0.5}>
                  شماره فیش
                </Typography>
                <CacheProvider value={cacheRtl}>
                  <TextField
                    inputProps={{ type: "number" }}
                    sx={{ marginBottom: 2, direction: "rtl", width: "100%" }}
                    label="شماره فیش"
                    variant="outlined"
                    value={receiptNumber}
                    onChange={(e) => {
                      setReceiptNumber(e.target.value);
                    }}
                  />
                </CacheProvider>
              </>
            ) : (
              ""
            )}
            <SignaturePad ref={sigPad} />
            <Button onClick={() => clear()} variant="contained" color="error">
              پاک کردن
            </Button>
            <Stack direction="row-reverse">
              <Button onClick={() => submitForm()} variant="contained" color="success">
                ثبت
              </Button>
              <Button sx={{ margin: "0 5px 0 5px" }} onClick={() => handleClose()} variant="outlined" color="error">
                لغو
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default DeliverToCustomerModal;
