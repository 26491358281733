import React, { useEffect } from "react";

const NotFound = () => {
  return (
    <>
      <p>404 not found</p>
    </>
  );
};
export default NotFound;
