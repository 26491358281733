import agent from "./agent";

export const changeStateByCargo = async (data) => {
  try {
    const response = await agent.Order.changeStateByCargo(data);
    return response;
  } catch (error) {
    throw error;
  }
};
