import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import routes from "../constants/routes";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(routes.HOME);
  const navigate = useNavigate();

  function handleChange(e, newValue) {
    setValue(newValue);
    navigate(newValue);
  }
  return (
    <Box sx={{ position: "fixed", bottom: 0, left: 0, right: 0, overflow: "auto" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(e, newValue) => {
          handleChange(e, newValue);
        }}
      >
        <BottomNavigationAction value={routes.HOME} label="داشبورد" icon={<HomeIcon />} />
        <BottomNavigationAction value={routes.CARGO} label="کارگو" icon={<LocalShippingIcon />} />
        <BottomNavigationAction value={routes.FINANCE} label="مالی" icon={<AttachMoneyIcon />} />
        <BottomNavigationAction value={routes.ORDERS} label="سفارشات" icon={<ShoppingCartIcon />} />
        <BottomNavigationAction value={routes.RETURNS} label="برگشتی" icon={<UTurnLeftIcon />} />
        <BottomNavigationAction value={routes.BULKCHANGE} label="عملیات" icon={<DeveloperBoardIcon />} />
      </BottomNavigation>
    </Box>
  );
}
