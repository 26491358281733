import React from "react";
import "./styles/App.scss";
import { ToastContainer } from "react-toastify";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routes from "./constants/routes";
import ProtectedRoute from "./components/HOC/ProtectedRoute";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Home,Cargo, Login, Orders, Returns, NotFound, Finance } from "./screens";
import Navbar from "./components/NavBarMenu";
import BottomNavigation from "./components/BottomNavigation";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import BulkChange from "./screens/orders/BulkChange";

function App() {
  const [cookies] = useCookies(["jwt"]); // Use 'jwt' as the cookie key

  const token = cookies["jwt"];

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Yekanbakhsh",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path={routes.LOGIN} element={<Login />} />
            <Route
              path={routes.HOME}
              exact
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.CARGO}
              exact
              element={
                <ProtectedRoute>
                  <Cargo />
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.FINANCE}
              element={
                <ProtectedRoute>
                  <Finance />
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.ORDERS}
              element={
                <ProtectedRoute>
                  <Orders />
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.RETURNS}
              element={
                <ProtectedRoute>
                  <Returns />
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.BULKCHANGE}
              element={
                <ProtectedRoute>
                  <BulkChange />
                </ProtectedRoute>
              }
            />
            <Route path='*' exact element={<NotFound />} />
          </Routes>
          {token && <BottomNavigation />}
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
