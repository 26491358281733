import axios from "axios";
import { toast } from "react-toastify";
import { BASE_NEDEX_URL } from "./config";

axios.defaults.baseURL = BASE_NEDEX_URL;

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error.response) {
      if (error.message === "Network Error" && !error.response) {
      }
      if (error.response) {
        const { status } = error.response;
        if (status) {
          if (status === 401) {
            // deleteCookie("jwtx");
            window.open("/login", "_self");
          }
          if (status === 404) {
            return error;
          }
          if (status === 400) {
          }
          if (status === 500) {
            toast.error("خطای سرور");
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => {
  return response;
};
const errorBody = (error) => {
  return error;
};

const requests = {
  get: (url) => axios.get(url).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody).catch(errorBody),
  put: (url, body) => axios.put(url, body).then(responseBody).catch(errorBody),
  del: (url) => axios.delete(url).then(responseBody),
};

const User = {
  register: (data) =>
    requests.post(`appApi/login`, {
      username: data.userName,
      password: data.password,
    }),
  access: (data) => requests.post(`/appApi/accessBaseRole`, data),
};
const Order = {
  loadUserOrderReturn: (data) => requests.post(`appApi/loadUserOrderReturn`, data),
  loadUserOrder: (data) => requests.post(`appApi/loadUserOrder`, data),
  loadUserOrderInfo: (data) => requests.post(`appApi/loadUserOrderInfo`, data),
  loadUserOrderCount: (data) => requests.post(`appApi/loadUserOrderCount`, data),
  changerUserOrderState: (data) => requests.post(`appApi/changeState`, data),
  loadDefaultMessage: (data) => requests.post(`appApi/loadDefaultMessage`, data),
  loadOrderComments: (data) => requests.post(`appApi/loadOrderComments`, data),
  loadOrderAttachment: (data) => requests.post(`appApi/loadOrderAttachment`, data),
  loadDeliveryInfoPrice: (data) => requests.post(`appApi/loadDeliveryInfoPrice`, data),
  loadCargo: (data) => requests.post(`appApi/loadUserCargo`, data),
  loadEmployeeOrderTotal: (data) => requests.post(`appApi/loadEmployeeOrderTotal`, data),
  changeStateByCargo: (data) => requests.post(`appApi/changeStateByCargo`, data),
  addComment: (data) => requests.post(`appApi/addComment`, data),
  uploadFile: (data) => requests.post(`appApi/upload`, data),
  orderPayment: (data) => requests.get(`api/startPayment/${data.orderSerial}/${data.wstoken}`),
  checkPosTransaction: (data) => requests.get(`api/checkPosTransaction/${data.orderSerial}/${data.wstoken}`),
  createReturnCargo: (data) => requests.post(`appApi/createReturnCargo`,data),
  addOrderToReturnCargo: (data) => requests.post(`appApi/addOrderToReturnCargo`,data),
  sendReturnCargo: (data) => requests.post(`appApi/sendReturnCargo`,data),
  uploadCargoFile: (data) => requests.post(`appApi/uploadCargo`, data),
  loadCargoAttachment: (data) => requests.post(`appApi/loadCargoAttachment`, data),
  bulkChangeState: (data) => requests.post(`appApi/bulkChangeState`, data),
  bulkAddToReturnCargo: (data) => requests.post(`appApi/bulkAddToReturnCargo`, data),
};

export default {
  User,
  Order,
};
