import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";

export default function LoadingOverlay(open) {
  
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,flexDirection:'column' }}
        open={open}
      >
        <Typography variant='h5' align='center' mb={2} >لطفا کارت خود را بکشید</Typography>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}