import agent from "./agent";
export const login = async (data) => {
  try {
    const response = await agent.User.register(data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const access = async (data) => {
  try {
    const response = await agent.User.access(data);
    return response;
  } catch (error) {
    throw error;
  }
};
